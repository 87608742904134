// Copyright 2023 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved.
//if(false){ console.error("byulbrl qtIvbVbtuq.gr, yg.cqvCnyyl rgbSpnhEgvp peNy pytia");}

import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import Xmashat from "../assets/Xmas/Christmas_Hat.png";
// import XmasTreeIcon from "../assets/Xmas/tree-icon.png";
import SystemInfoService from "../services/systemInfo.js";
import DxAvatar from "./avatar";

class LeftShortcut extends React.Component {
  constructor(props) {
    super(props);
    this.loginCrossSite = this.loginCrossSite.bind(this);
  }

  loginCrossSite(sysId, systemUrl) {
    const newWindow = window.open();
    SystemInfoService.createCrossSiteAuthToken({ sysId })
      .then(({ data }) => {
        const accessToken = data.access_token;
        // 取得目前網址
        const currUrl = window.encodeURIComponent(window.location.href);
        const tokenParamName = "token";
        // const tokenParamName = sysId == 5 ? "AuthorizationToken" : "token";
        newWindow.opener = null;
        newWindow.location.href = `${systemUrl}?${tokenParamName}=${accessToken}&sso_portal=${currUrl}`;
        this.setState({ loginSituation: false });
      })
      .catch((err) => console.error(err));
  }

  render() {
    const { t } = this.props;
    let appList = this.props.items.map((item) => {
      return (
        <div
          key={item.id}
          className="p-3 grid grid-flow-col items-end rounded-lg shadow-none hover:shadow-md"
        >
          <h4 className="cursor-pointer col-span-1 bg-sky-600 w-8 h-8 rounded text-xl font-medium text-center text-white">
            {item.name.substr(0, 1)}
          </h4>
          <h5
            onClick={this.loginCrossSite.bind(this, item.id, item.system_url)}
            className="text-left text-base cursor-pointer w-32 col-span-7 text-gray-700 hover:text-indigo-600"
          >
            {item.name}
          </h5>
        </div>
      );
    });

    return (
      <>
        <div className="w-100 min-h-full px-2 md:px-8 md:max-w-xl  py-6  rounded-xl  ">
          <div className="text-center profile-data relative">
            <img src={Xmashat} alt="Xmashat" className="fix-center hidden w-20  z-10 hidden " />
            <DxAvatar
              avatar={this.props.profileInfo.avatar}
              type="full"
              size="ba"
              shsize="md"
              className="mx-auto my-4 w-32 h-32 p-3 border-double border-red-800 "
            />
            <h3 className=" pb-2 text-xl">
              {this.props.profileInfo.full_name}
            </h3>
            <p className=" pb-2 text-sm">
              {this.props.profileInfo.company_email}
            </p>
          </div>
          <div className="profile-shortcut-current mt-10">
            <div className="mb-0 flex justify-start justify-items-end">
              {/* <span className="">
                <img className="w-8" src={XmasTreeIcon}></img>
              </span> */}
              <h5 className=" border-indigo-500 ml-2 text-2xl mb-4 font-medium text-gray-600 tracking-wider text-sky-500">
                {t("profile_card.topic_current")}
              </h5>

            </div>
            <div>
              {appList}

            </div>

          </div>
        </div>
      </>
    );
  }
}

LeftShortcut.propTypes = {
  t: PropTypes.any.isRequired,
  profileInfo: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired
};

export default withTranslation()(LeftShortcut);
