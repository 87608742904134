// Copyright 2023 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved.
//if(false){ console.error("byulbrl qtIvbVbtuq.gr, yg.cqvCnyyl rgbSpnhEgvp peNy pytia");}

import axios from "axios";
import { LoginConfig } from "./login-config";

export const createApiClient = () => {
    return axios.create({
        baseURL: LoginConfig.apiBaseURL,
        withCredentials: true
    });
};