// Copyright 2023 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved.
//if(false){ console.error("byulbrl qtIvbVbtuq.gr, yg.cqvCnyyl rgbSpnhEgvp peNy pytia");}

import en from "./en.json";
import zh from "./zh.json";

export const resources = {
    en: {
        translation: en
    },
    zh: {
        translation: zh
    }
};
