// Copyright 2023 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved.
//if(false){ console.error("byulbrl qtIvbVbtuq.gr, yg.cqvCnyyl rgbSpnhEgvp peNy pytia");}

import PropTypes from "prop-types";
import React from "react";

// import { AccessAlarm, ThreeDRotation } from '@mui/icons-material';
// import styled from 'styled-components';
export const ColorType = {
    basic: "bg-blue-300 hover:bg-blue-400  text-white font-bold  shadow-md  tracking-wide  ",
    primary: "bg-blue-400 hover:bg-blue-500  text-white font-bold  shadow-md  tracking-wide  ",
    secondary: "bg-slate-300 hover:bg-slate-400  text-white font-bold  shadow-md  tracking-wide  ",
    danger: "bg-red-400 hover:bg-red-500 text-white font-bold  shadow-md  tracking-wide  ",
    warning: "bg-yellow-500 hover:bg-yellow-600 text-white font-bold  shadow-md  tracking-wide  ",
    success: "bg-teal-600 hover:bg-teal-700 text-white font-bold  shadow-md  tracking-wide  ",
    info: "bg-sky-400 hover:bg-sky-500 text-white font-bold  shadow-md  tracking-wide  ",
    dark: "bg-slate-800 hover:bg-slate-900 text-white font-bold  shadow-md  tracking-wide  "
};

export const ElementSize = {
    sm: " py-1 p-3 text-xs ",
    md: " py-2 p-4 text-sm ",
    lg: " py-3 p-5 text-base ",
    xl: " py-4 p-6 text-lg "
};

class DxCommonButton extends React.Component {
    render() {
        const roundedClassName = this.props.rounded ? "rounded-md" : "rounded-none";
        const classNames = [this.props.className, ColorType[this.props.type], ElementSize[this.props.size], roundedClassName];
        if (this.props.disabled) {
            classNames.push(...["disabled:opacity-50", "cursor-not-allowed"]);
        }

        return (
            <>
                <button className={classNames.join(" ")} disabled={this.props.disabled}>{this.props.children}</button>
            </>
        );
    }
}

DxCommonButton.propTypes = {
    type: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    rounded: PropTypes.bool,
    disabled: PropTypes.bool
};

export default DxCommonButton;
