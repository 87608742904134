// Copyright 2023 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved.
//if(false){ console.error("byulbrl qtIvbVbtuq.gr, yg.cqvCnyyl rgbSpnhEgvp peNy pytia");}

import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import ECVLogo from "../assets/ECV_logo.png";
import Xmashat from "../assets/Xmas/Christmas_Hat.png";
import DxAvatar from "../components/avatar";
import DxDropdown from "../components/dropdown";
import LanguageSwitch from "../components/i18n/languageSwitch";
class DxNavigation extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { t } = this.props;
        // console.log(this.props, 'nav');
        return (
            <>
                <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-white mb-3 ">
                    <div className="container px-4 mx-auto flex flex-wrap items-end justify-between">
                        <div className="w-auto  relative flex items-start justify-between lg:w-auto lg:static lg:block lg:justify-start">
                            <img src={ECVLogo} alt="logo" className="inline-block mx-auto logoWhite mb-3" />
                            <span className="bg-clip-text text-transparent bg-gradient-to-r from-yellow-300 to-sky-400 text-2xl pl-3 text-sky-400 font-bold leading-relaxed  mr-4 py-2 whitespace-nowrap tracking-widest text-gray-800 hidden md:inline-block">
                                {t("nav_menu.platform_name")}
                            </span>
                            <button
                                className="text-gray-800 cursor-pointer text-2xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                                type="button"
                            // onClick={() => setNavbarOpen(!navbarOpen)}
                            >
                                <i className="fas fa-bars"></i>
                            </button>
                        </div>
                        <div
                            className="lg:flex flex-grow items-center justify-end flex "
                            // className={'lg:flex flex-grow items-center' + (navbarOpen ? ' flex' : ' hidden')}
                            id="example-navbar-danger">
                            <ul className="flex  lg:flex-row list-none items-center justify-center lg:ml-auto">
                                {/* <li className="nav-item ">
                                    <a
                                        className="px-3 pt-4 py-2 flex items-center text-xs font-bold leading-snug text-gray-800 hover:opacity-75"
                                        href="#pablo">
                                        <i className="fab fa-facebook-square text-lg leading-lg text-gray-800 opacity-75"></i>
                                        <div className="relative">
                                            <DXAlermessage />
                                           
                                        </div>
                                    </a>
                                </li> */}
                                <li className="hidden nav-item ">
                                    <LanguageSwitch />
                                </li>
                                <li className="nav-item hidden md:block">
                                    <a
                                        className="px-3 py-2 flex items-center text-2xl font-bold leading-snug text-gray-800 hover:opacity-75 relative"
                                        href="#">
                                        <i className="fab fa-twitter text-lg leading-lg text-gray-800 opacity-75"></i>

                                        <DxAvatar avatar={this.props.profileInfo.avatar} type="full" size="sm" shsize="md" className="h-10" />
                                        <img src={Xmashat} alt="Xmashat" className=" hidden mb-2 absolute top-0 right-2 w-8 z-10  " />
                                    </a>
                                </li>
                                <li className="nav-item ">
                                    <DxDropdown profileInfo={this.props.profileInfo} />
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </>
        );
    }
}

DxNavigation.propTypes = {
    t: PropTypes.any.isRequired,
    profileInfo: PropTypes.object.isRequired
};

export default withTranslation()(DxNavigation);
