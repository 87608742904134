// Copyright 2023 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved.
//if(false){ console.error("byulbrl qtIvbVbtuq.gr, yg.cqvCnyyl rgbSpnhEgvp peNy pytia");}

import LogoutIcon from "@mui/icons-material/Logout";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import DxAvatar from "../components/avatar";
import DxCommonButton from "../components/button";
import "../style/TurnCard.css";
// import axiosInstance from "./config/axios";
import SystemInfoService from "../services/systemInfo";
class AppCard extends React.Component {
  constructor(props) {
    super(props);
    this.loginCrossSite = this.loginCrossSite.bind(this);
  }

    loginCrossSite() {
        // console.log(this.props);
        const sysId = this.props.id;
        const systemUrl = this.props.system_url;
        const newWindow = window.open();
        SystemInfoService.createCrossSiteAuthToken({ sysId }).then(({ data }) => {
            const accessToken = data.access_token;
            // 取得目前網址
            const currUrl = window.encodeURIComponent(window.location.href);
            const tokenParamName = "token";
            // const tokenParamName = sysId == 5 ? "AuthorizationToken" : "token";
            newWindow.opener = null;
            newWindow.location.href = `${systemUrl}?${tokenParamName}=${accessToken}&sso_portal=${currUrl}`;
            this.setState({ loginSituation: false });
        }).catch((err) => console.error(err));
    }

  render() {
    const { t } = this.props;
    // const system_url = {this.props.system_url};
    // const id = {this.props.id};
    // const { name } = this.props;
    // console.log(this.props, 'appcard');
    // const lineOffStatus = {
    //     backgroundColor: '#ffa4a4'
    // };
    // const onLineStatus = {
    //     backgroundColor: '#c1e3f5'
    // };
    return (
      <>
        <div className="     col-span-1  mb-5 relative flip-box w-100 md:w-46 lg:w-46 xl:w-56   block m-auto rounded-xl  ">
          {/* 整個卡片 */}
          <div className="flip-box-inner  rounded-xl shadow-md">
            {/* 卡片正面 */}
            <div className="flip-box-front flex justify-items-center items-center h-full ">
              {/* <span
                                className="absolute  h-2 w-2 bg-red-400 rounded-full top-3 right-3 opacity-75 inline-flex "
                                style={loginSituation == true ? lineOffStatus : onLineStatus}></span>
                            <span
                                className="absolute animate-ping h-2 w-2 bg-red-400 rounded-full top-3 right-3 opacity-75 inline-flex"
                                style={loginSituation == true ? lineOffStatus : onLineStatus}></span> */}
              <div className="text-left  mx-auto block w-32 h-28 ">
                <div className="">
                  <div className="bg-white w-8 h-8 rounded text-2xl font-medium text-center text-sky-600 mb-1">
                    {this.props.name.substr(0, 1)}
                  </div>
                  <h2 className="text-xl tracking-widest font-medium">
                    {this.props.name}
                  </h2>
                  {/* <p>Produced in 2022</p> */}
                </div>
              </div>
            </div>
            {/* 卡片背面 */}
            <div className="flip-box-back p-3 bg-white  ">
              {/* <DxLogo type="GA" className="mb-3" shsize="md" size="sm" /> */}
              <h4 className="mb-4 text-2xl text-gray-700 font-semibold">
                {this.props.name}
              </h4>
              <div className="mb-8">
                <p className=" text-left  h-24 overflow-y-auto pr-4 mb-2">
                  {this.props.description}
                </p>
              </div>
              <div className="mx-auto my-4 grid grid-flow-col items-end justify-end relative py-2 hidden ">
                <DxAvatar
                  type="full"
                  size="sm"
                  shsize="md"
                  className="inline-block absolute top-0 left-0"
                />
                <DxAvatar
                  type="full"
                  size="sm"
                  shsize="md"
                  className="inline-block absolute top-0 left-1"
                />
                <DxAvatar
                  type="full"
                  size="sm"
                  shsize="md"
                  className="inline-block absolute top-0 left-2"
                />

                <span className="text-gray-400 text-xs px-1 ">
                  Online Number People 400
                </span>
              </div>
              <div className="flex justify-between">
                <div
                  onClick={
                    this.props.enable === "Y" ? this.loginCrossSite : null
                  }
                  className="w-9/12 mx-auto"
                >
                  <DxCommonButton
                    type="primary"
                    className="min-w-full"
                    size="md"
                    rounded
                    disabled={this.props.enable === "N"}
                  >
                    <LogoutIcon className="pr-1 text-3xl " />
                    {t("app_card.go")}
                    {/* {t('app_card.login')} */}
                    {/* {t(loginSituation == true ? 'app_card.login' : 'app_card.logout')} */}
                  </DxCommonButton>
                </div>
                {/* <div onClick={this.forwardPage} className={loginSituation == true ? 'hidden' : 'w-5/12 ml-1 '}>
                                    <DxCommonButton type="success" size="md" className="min-w-full" rounded>
                                        <LaunchIcon className=" text-3xl " />
                                        {t('app_card.go')}
                                    </DxCommonButton>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

AppCard.propTypes = {
  t: PropTypes.any.isRequired,
  i18n: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  system_url: PropTypes.string,
  id: PropTypes.number.isRequired,
  enable: PropTypes.string.isRequired
};

export default withTranslation()(AppCard);
