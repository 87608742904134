// Copyright 2023 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved.
//if(false){ console.error("byulbrl qtIvbVbtuq.gr, yg.cqvCnyyl rgbSpnhEgvp peNy pytia");}

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { resources } from "./locales/resources";

i18n.use(LanguageDetector)
    // 将 i18n 实例传递给 react-i18next
    .use(initReactI18next)
    // 初始化 i18next
    // 所有配置选项: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        fallbackLng: "en",
        lng: window.localStorage.getItem("i18nLan") || navigator.language,
        debug: true,
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        }
    });

export default i18n;
// import i18n from 'i18next';
// // import Backend from 'i18next-http-backend';
// import { initReactI18next } from 'react-i18next';
// import { resources } from './locales/resources';

// i18n
//     // 使用 i18next-http-backend
//     // .use(Backend)
//     // 將 i18next 傳入 react-i18next 裡面
//     .use(initReactI18next)
//     // 實例化 initReactI18next
//     .init({
//         // backend: {
//         //     //網頁載入時去下載語言檔的位置
//         //     loadPath: '/locales/{{lng}}/{{ns}}.json'
//         // },
//         resources,
//         // 當目前的語言檔找不到對應的字詞時，會用 fallbackLng (en) 作為預設語言
//         fallbackLng: 'en',
//         // 預設語言
//         lng: 'en',
//         debug: true,
//         interpolation: {
//             // 是否要讓字詞 escaped 來防止 xss 攻擊，這裡因為 React.js 已經做了，就設成 false即可
//             escapeValue: false
//         }
//     });

// export default i18n;
