// Copyright 2023 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved.
//if(false){ console.error("byulbrl qtIvbVbtuq.gr, yg.cqvCnyyl rgbSpnhEgvp peNy pytia");}

import PropTypes from "prop-types";
import React from "react";
export const RoundType = {
    none: "rounded-none",
    sm: "rounded",
    md: "rounded-md",
    lg: "rounded-lg",
    full: "rounded-full"
};
export const ElementSize = {
    sm: "  w-10 ",
    md: "  w-24 ",
    ba: "  w-32 ",
    lg: "  w-48 ",
    xl: "  w-64 "
};
export const ShadowSize = {
    sm: " shadow ",
    md: " shadow-md ",
    lg: " shadow-lg ",
    xl: " shadow-xl "
};

class DxAvatar extends React.Component {

    render() {
        const classNames = [this.props.className, RoundType[this.props.type], ShadowSize[this.props.shsize], ElementSize[this.props.size]].join(" ");
        return (
            <>
                <img src={this.props.avatar} className={classNames} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "https://cdn-icons-png.flaticon.com/512/149/149071.png";
                }} />
            </>
        );
    }
}

DxAvatar.propTypes = {
    type: PropTypes.string,
    size: PropTypes.string,
    shsize: PropTypes.string,
    className: PropTypes.string,
    avatar: PropTypes.string
};

export default DxAvatar;
