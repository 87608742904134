// Copyright 2023 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved.
//if(false){ console.error("byulbrl qtIvbVbtuq.gr, yg.cqvCnyyl rgbSpnhEgvp peNy pytia");}

import LanguageIcon from "@mui/icons-material/Language";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

class LanguageSwitch extends React.Component {
    constructor(props) {
        super(props);
        this.cardWrapper = React.createRef();
        this.languageCard = React.createRef();
        this.switchEnghlish = this.switchEnghlish.bind(this);
        this.switchMandarin = this.switchMandarin.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleClickDropdown = this.handleClickDropdown.bind(this);
    }

    componentDidMount() {
        document.addEventListener("click", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside);
    }

    /**
    * Close dropdown if clicked on outside of element
    */
    handleClickOutside(event) {
        if (this.cardWrapper && !this.cardWrapper.current.contains(event.target)) {
            this.languageCard.current.classList.add("hidden");
        }
    }

    switchEnghlish() {
        const { i18n } = this.props;
        i18n.changeLanguage("en");
        window.localStorage.setItem("i18nLan", "en");
    }
    switchMandarin() {
        const { i18n } = this.props;
        i18n.changeLanguage("zh");
        window.localStorage.setItem("i18nLan", "zh");
    }

    handleClickDropdown() {
        const languageCardElem = this.languageCard.current;
        languageCardElem.classList.toggle("hidden");
        languageCardElem.classList.toggle("animate__animated");
        languageCardElem.classList.toggle("animate__fadeIn");
        languageCardElem.classList.toggle("animate__faster");
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <div ref={this.cardWrapper} className="relative">
                    <LanguageIcon onClick={this.handleClickDropdown} className="Language  mr-4 cursor-pointer  text-gray-400 hover:text-gray-500" />
                    <div ref={this.languageCard} className="hidden languagecard absolute top-8 right-1  shadow-md">
                        <div className="bg-white rounded-md p-3 w-36 text-start ">
                            <div onClick={this.switchEnghlish} className="py-2 px-1  cursor-pointer text-gray-500 hover:text-gray-700">
                                <a className="whitespace-nowrap">{t("language.en")}</a>
                            </div>
                            <div onClick={this.switchMandarin} className="py-2 px-1  cursor-pointer text-gray-500 hover:text-gray-700">
                                <a className="whitespace-nowrap"> {t("language.ch")}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

LanguageSwitch.propTypes = {
    t: PropTypes.any.isRequired,
    i18n: PropTypes.any.isRequired
};

export default withTranslation()(LanguageSwitch);
