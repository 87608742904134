// Copyright 2023 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved.
//if(false){ console.error("byulbrl qtIvbVbtuq.gr, yg.cqvCnyyl rgbSpnhEgvp peNy pytia");}

import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import XmSnowMan from "../assets/Xmas/snow-man.png";
// import XmasTreeIcon from "../assets/Xmas/tree-icon.png";
import AppCard from "../components/appCard";
class RightAppCard extends React.Component {
  constructor(props) {
    super(props);
  }
  // componentDidMount() {}

  render() {
    const { t } = this.props;
    const filterItems = this.props.items.filter((item) => item.visible);
    let rightCardBody;
    if (filterItems.length > 0) {
      if (filterItems.every((item) => !item.visible)) {
        rightCardBody = (
          <h1 className="text-3xl text-center text-slate-400">
            {t("app_card.no_result")}
          </h1>
        );
      }
      const appCardList = filterItems.map((item) => {
        return (
          <AppCard
            key={item.id}
            name={item.name}
            id={item.id}
            system_url={item.system_url}
            description={item.description}
            enable={item.sso_login}
          ></AppCard>
        );
      });
      rightCardBody = (
        <div className="grid px-1 grid-rows-3 grid-flow-row grid-flow-row sm:grid-flow-row md:grid-flow-row  sm:grid-cols-3 md:grid-clos-3 grid-clos-1 gap-x-2  sm:gap-x-1 md:gap-x-3 lg:gap-x-5  items-end mb-3">
          {/* <AppCard />
                    <AppCard />
                    <AppCard /> */}
          {appCardList}
        </div>
      );
    }
    return (
      <>
        <div className="w-100  p-4 rounded-xl   h-100">
          <div className="mb-4 flex justify-start justify-items-end">
            {/* <span className="">
              <img className="w-8" src={XmasTreeIcon}></img>
            </span> */}
            <h3 className=" pl-5  text-2xl  font-medium text-gray-600 tracking-widest text-sky-500 ">
              {t("profile_card.topic_current")}
            </h3>
          </div>

          {rightCardBody}
          <div className="christmas-ribbon-wrapper">
            <div className="christmas-ribbon">
              <a
                href="javascript:void(0);"
                className="cursor-default"
              >
                Happy New Year
              </a>
            </div>
          </div>
          <img src={XmSnowMan} alt="XmSnowMan" className=" w-36 ml-auto  fixed bottom-0 right-1  z-10" />
        </div>
      </>
    );
  }
}

RightAppCard.propTypes = {
  t: PropTypes.any.isRequired,
  i18n: PropTypes.any.isRequired,
  items: PropTypes.array.isRequired
  // itemArr: PropTypes.object
};

// export default rightAppCard;
export default withTranslation()(RightAppCard);
