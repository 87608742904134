// Copyright 2023 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved.
//if(false){ console.error("byulbrl qtIvbVbtuq.gr, yg.cqvCnyyl rgbSpnhEgvp peNy pytia");}

import { createApiClient } from "./config/axios-config.js";
import { LoginConfig } from "./config/login-config.js";

export default class AuthRequiredService {

  #apiClient = createApiClient();

  constructor() {
    // Set token in `Authorization` header in each request
    this.#apiClient.interceptors.request.use(config => {
      const token = window.localStorage.getItem("accessToken");
      config.headers.Authorization = token ? `Bearer ${token}` : "";

      return config;
    });
    this.#apiClient.interceptors.response.use(response => response, error => {
      if ([401, 403, 404].includes(error.response.status)) {
        window.location.href = `${LoginConfig.indexPageURL}/login`;
      }
      return error;
    });
  }

  useApiClient() {
    return this.#apiClient;
  }
}