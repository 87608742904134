// Copyright 2023 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved.
//if(false){ console.error("byulbrl qtIvbVbtuq.gr, yg.cqvCnyyl rgbSpnhEgvp peNy pytia");}

import Swal from "sweetalert2";
import { createApiClient } from "./config/axios-config.js";
import { LoginConfig } from "./config/login-config.js";

class LoginService {

  #loginUrl = `${LoginConfig.indexPageURL}/login`;
  #apiClient = createApiClient();

  ssoLogin() {
    window.location.href = `${LoginConfig.apiBaseURL}/auth/login`;
  }

  ssoLogout() {
    window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${this.#loginUrl}`;
  }

  async mockLogin({ username }) {
    return this.#apiClient({
      method: "POST",
      url: "/auth/login",
      data: {
        username: username,
        password: username
      }
    }).then(({ data }) => {
      // 登入從後端拿取 token  localStorage
      window.localStorage.setItem("accessToken", data.access_token);
      window.location.href = `${LoginConfig.indexPageURL}/index`;
    })
      .catch(error => {
        console.error(error);
        Swal.fire({
          icon: "error",
          title: "Login Fail",
          html: "<h2>User does not exist in db</h2>"
        });
      });
  }

  sysLogout() {
    // SSO logout
    const token = window.localStorage.getItem("accessToken");
    this.#apiClient({
      method: "POST",
      url: "/auth/logout",
      params: {
        site: 6
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(() => {
      window.location.href = this.#loginUrl;
    });
    // window.location.replace(`https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${login_url}`);
  }
}

export default new LoginService();