// Copyright 2023 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved.
//if(false){ console.error("byulbrl qtIvbVbtuq.gr, yg.cqvCnyyl rgbSpnhEgvp peNy pytia");}

import React from "react";
import { withTranslation } from "react-i18next";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import DXLogin from "../pages/Login";
import DXPlatefrom from "../pages/Plateform";
import "../style/App.css";

// <Login />
const Login = () => {
    return (
        <>
            <DXLogin></DXLogin>
        </>
    );
};
const Platefrom = () => {
    return (
        <>
            <DXPlatefrom></DXPlatefrom>
        </>
    );
};
function App() {
    const routerBase = ["local", "lab"].includes(process.env.REACT_APP_ENV) ? "/bifrost" : "/";
    return (
        <Router basename={routerBase}>
            <Routes>
                {/* <Route element={<Login />} path="/"></Route> */}
                <Route element={<Login />} path="/login"></Route>
                <Route element={<Login />} path="/"></Route>
                <Route element={<Platefrom />} path="/index"></Route>
            </Routes>
        </Router>
    );
}

export default withTranslation()(App);
