// Copyright 2023 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved.
//if(false){ console.error("byulbrl qtIvbVbtuq.gr, yg.cqvCnyyl rgbSpnhEgvp peNy pytia");}

import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";

import PropTypes from "prop-types";
import React from "react";
import DxAvatar from "../components/avatar";
import DxCommonButton from "../components/button";
// import { LoginConfig } from "./config/login";
import Xmashat from "../assets/Xmas/Christmas_Hat.png";
import LoginService from "../services/login.js";

// const pageURL = LoginConfig.indexPageURL;
class DxDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isToggleon: true };
        this.cardWrapper = React.createRef();
        this.profileCard = React.createRef();
        this.ssoLogout = this.ssoLogout.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleClickDropdown = this.handleClickDropdown.bind(this);
    }

    componentDidMount() {
        document.addEventListener("click", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside);
    }

    /**
    * Close dropdown if clicked on outside of element
    */
    handleClickOutside(event) {
        if (this.cardWrapper && !this.cardWrapper.current.contains(event.target)) {
            this.profileCard.current.classList.add("hidden");
        }
    }

    ssoLogout() {
        LoginService.sysLogout();
    }

    handleClickDropdown() {
        const profileCardElem = this.profileCard.current;
        profileCardElem.classList.toggle("hidden");
        profileCardElem.classList.toggle("animate__animated");
        profileCardElem.classList.toggle("animate__fadeIn");
        profileCardElem.classList.toggle("animate__faster");
    }

    render() {
        return (
            <>
                <div className="relative" ref={this.cardWrapper}>
                    <button onClick={this.handleClickDropdown}>
                        <a
                            className="py-2  flex items-center text-2xl font-bold leading-snug relative text-gray-800 hover:opacity-75"
                            href="#">
                            <p className="text-gray-600 inline-block text-sm md:text-xl">{this.props.profileInfo.full_name}</p>
                            {/* {this.state.isToggleon ? 'ON' : 'OFF'} */}
                            <ArrowDropDownRoundedIcon className=" text-4xl ArrowDropDownRounded" />
                        </a>
                    </button>
                    {/* <Triangle className="absolute top-24 p-7 right-5  w-30  z-10">wrgwgerg</Triangle> */}
                    <div
                        ref={this.profileCard}
                        className="profilecard hidden w-80 h-50 absolute top-23 right-5  grid grid-flow-col bg-white pt-5 pb-3 px-3 shadow-lg rounded-bl-lg rounded-br-lg z-10"
                    >
                        <div className="col-span-2 p-1">
                            <DxAvatar avatar={this.props.profileInfo.avatar} type="full" shsize="md" className="w-12 h-12" />
                            <img src={Xmashat} alt="Xmashat" className="hidden mb-2 absolute top-4 left-9 w-7 z-10   " />
                        </div>
                        <div className="col-span-2 p-1">
                            {/* <h3 className="text-xl font-semibold">Personal Profile</h3> */}
                            <p>{this.props.profileInfo.full_name}</p>
                            <p>{this.props.profileInfo.company_email}</p>
                            <hr />
                            <div className="text-right" onClick={this.ssoLogout}>
                                <DxCommonButton className="w-15 mt-2" type="primary" size="md" rounded>
                                    Sign out
                                </DxCommonButton>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

DxDropdown.propTypes = {
    profileInfo: PropTypes.object.isRequired
};

export default DxDropdown;
