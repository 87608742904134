// Copyright 2023 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved.
//if(false){ console.error("byulbrl qtIvbVbtuq.gr, yg.cqvCnyyl rgbSpnhEgvp peNy pytia");}

import AuthRequiredService from "./base.js";

class ProfileInfoService extends AuthRequiredService {

  constructor() {
    super();
  }

  fetchProfileInfo({ lang }) {
    return super.useApiClient().get("/api/v1/profileInfo", { headers: { "Accept-Lang": lang } });
  }
}

export default new ProfileInfoService();