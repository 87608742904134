// Copyright 2023 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved.
//if(false){ console.error("byulbrl qtIvbVbtuq.gr, yg.cqvCnyyl rgbSpnhEgvp peNy pytia");}

import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import logoWhite from "../assets/logo-white.png";
import DxCommonButton from "../components/button";
import LanguageSwitch from "../components/i18n/languageSwitch";
import LoginService from "../services/login.js";
import "../style/Login.css";

// 現在要把後端帶回的tooken 存 localStorage 並夾帶在header中 打api/systemInfo
const mockLoginEnable = JSON.parse(process.env.REACT_APP_MOCK_LOGIN_ENABLE);
const urlParams = new URLSearchParams(window.location.search);
const accessToken = urlParams.get("access_token");
if (accessToken) {
  window.localStorage.setItem("accessToken", accessToken);
  window.history.replaceState(null, "", window.location.pathname);
}

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: ""
    };
    this.mockUserLogin = this.mockUserLogin.bind(this);
    this.ssoLogin = this.ssoLogin.bind(this);
    this.sysLogout = this.sysLogout.bind(this);
    this._handleKeyDown = this._handleKeyDown.bind(this);
    this.mockLoginTemplate = this.mockLoginTemplate.bind(this);
  }
  // const navigate = useNavigate()
  // 取input 使用者名稱
  // const [account, setAccount] = useState('');

  mockUserLogin() {
    let { username } = this.state;
    LoginService.mockLogin({ username });
  }

  ssoLogin() {
    LoginService.ssoLogin();
  }

  ssoLogout() {
    LoginService.ssoLogout();
  }

  sysLogout() {
    LoginService.sysLogout();
  }

  _handleKeyDown(e) {
    if (e.key === "Enter") {
      this.mockUserLogin();
    }
  }

  mockLoginTemplate(t) {
    if (mockLoginEnable) {
      return <div className="grid grid-rows-none grid-flow-col gap-2">
        <input
          type="text"
          className="h-12 py-1 bg-white border rounded-md shadow-sm pl-2 mt-2 md:w-80
                  focus:border-sky-200 invalid:text-pink-600
                  focus:invalid:border-pink-600
                  "
          onChange={(e) =>
            // console.log(e.target.value)
            this.setState({ username: e.target.value })
          }
          onKeyDown={this._handleKeyDown}
          placeholder={t("login_page.input_placeholder")}></input>
        <div onClick={this.mockUserLogin}>
          <DxCommonButton className="my-2 col-span-1 row-span-1 tracking-wider  h-12" type="primary" size="md" rounded>
            {t("login_page.user_login")}
          </DxCommonButton>
        </div>
      </div>;
    }
    return <div className="md:w-96"></div>;
  }

  render() {
    const { t } = this.props;
    return (
      <div className="Login min-h-full w-full p-5  ">
        <header className="container mx-auto  px-2 py-4  flex justify-center mt-14 relative">
          <div className="">
            <img src={logoWhite} alt="logo" className="block mx-auto logoWhite" />
            <h1 className=" text-4xl sm:text-5xl font-normal sm:font-medium md:font-semibold text-white text-center mt-4">
              Welcome ECVers
            </h1>
          </div>
          <div className="absolute top-1 right-10 z-index-10">
            <LanguageSwitch />
          </div>
        </header>
        <div className="container mx-auto px-2 py-4  flex justify-center mt-4">
          <div className="w-auto grid grid-flow-row-dense grid-flow-col gap-2">
            <div onClick={this.ssoLogin}>
              <DxCommonButton className="my-2 w-full" type="primary" size="lg" rounded>
                <span className="mr-2">
                  <GridViewOutlinedIcon className="GridViewOutlined" />
                </span>
                {t("login_page.sso_login")}
              </DxCommonButton>
            </div>
            <div onClick={this.ssoLogout}>
              <DxCommonButton className="my-2 w-full" type="warning" size="lg" rounded>
                <span className="mr-2">
                  <LogoutIcon className="logoutOutlined" />
                </span>
                {t("login_page.sso_logout")}
              </DxCommonButton>
            </div>
            {this.mockLoginTemplate(t)}
          </div>
        </div >
      </div >
    );
  }
}
Login.propTypes = {
  t: PropTypes.any.isRequired
};

export default withTranslation()(Login);
