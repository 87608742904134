// Copyright 2023 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved.
//if(false){ console.error("byulbrl qtIvbVbtuq.gr, yg.cqvCnyyl rgbSpnhEgvp peNy pytia");}

// import DxInputSearch from "../components/inputSearch.js";
import * as React from "react";
import Xmastree from "../assets/Xmas/xmas-footer-tree.png";
import LeftShortcut from "../components/leftShortcut";
import Navigate from "../components/navigate";
import RightAppCard from "../components/rightAppCard";
import { LoginConfig } from "../services/config/login-config.js";
import ProfileInfoService from "../services/profileInfo.js";
import SystemInfoService from "../services/systemInfo.js";
import "../style/Xmas.css";

class Platefrom extends React.Component {
  constructor(props) {
    super(props);
    this.getSystemInfo = this.listSystemInfos.bind(this);
    this.getBasicInfo = this.getBasicInfo.bind(this);
    this.filterSites = this.filterSites.bind(this);
    this.state = {
      error: null,
      isLoaded: false,
      keyword: "",
      items: [],
      profileInfo: {}
    };
    if (!window.localStorage.getItem("accessToken")) {
      window.location.href = `${LoginConfig.indexPageURL}/login`;
    }
  }

  async listSystemInfos(i18nLan) {
    return SystemInfoService.fetchSystemInfos({ lang: i18nLan })
      .then(({ data }) => {
        // console.log(res.data.data, 'api success');
        const sites = data.data.map((item) => {
          item.visible = true;
          return item;
        });
        this.setState({
          isLoaded: true,
          items: sites
        });
      })
      .catch((err) => {
        this.setState({
          isLoaded: true,
          err
        });
      });
  }

  async getBasicInfo(i18nLan) {
    ProfileInfoService.fetchProfileInfo({ lang: i18nLan })
      .then(({ data }) => {
        this.setState({
          profileInfo: data.data
        });
      })
      .catch((err) => {
        this.setState({
          isLoaded: true,
          err
        });
      });
  }
  componentDidMount() {
    let i18nLan = window.localStorage.getItem("i18nextLng");
    {
      this.listSystemInfos(i18nLan);
      this.getBasicInfo(i18nLan);
    }
  }
  filterSites(e) {
    const keyword = e.target.value;
    this.setState({ keyword });
    console.log(keyword);
    console.log(keyword);
    this.state.items.forEach((item) => {
      item.visible = `${item.name}: ${item.description}`.includes(keyword);
    });
  }

  render() {
    const footer = {
      position: "fixed",
      zIndex: "-1",
      bottom: "-64px"
    };
    return (
      <>
        <div className="snow2"></div>
        <div className="snow3"></div>
        <div className=" bg-gradient-to-b from-gray-200 to-gray-400  w-100  min-h-full   ">
          <div className="snow1 snow2 snow3">
            <Navigate profileInfo={this.state.profileInfo}></Navigate>
            <div className="  grid grid-cols-12 px-2  sm:grid-flow-row md:grid-flow-row laptop:grid-flow-row lg:grid-flow-col  gap-2 mx-auto mt-10  ">
              <div className="light-bg col-start-2  col-end-5 lg:col-start-2 xl:col-start-3 lg:col-end-6  min-h-full mx-2  hidden lg:block    ">
                {/* max-w-md md:max-w-[50%] lg:max-w-[75%] */}
                <LeftShortcut
                  profileInfo={this.state.profileInfo}
                  items={this.state.items}
                />
              </div>
              <div className="light-bg  col-start-2 col-end-12 sm:col-start-2 md:col-start-3 lg:col-start-6 lg:col-end-12 xl:col-end-11  block  min-h-full  ">
                {/* 這邊放卡片元件 */}

                <RightAppCard
                  items={this.state.items}
                  className=" mx-auto px-10 "
                />
                {/* <DxInputSearch
                                className="bg-white h-12 mb-10 py-3   outline-none sm:col-span-10 md:col-span-9 col-span-7  inline-flex border border-gray-300 text-gray-900 text-sm hover:border-sky-200 focus:border-sky-200 focus-visible:border-sky-200   block  p-2.5  "
                                shsize="md"
                                size="w-40"
                                value={this.state.keyword}
                                onChange={this.filterSites}
                            /> */}
              </div>
            </div>
            <div>
              <img
                src={Xmastree}
                alt="Xmastree"
                className="block w-100  mx-auto mb-3"
                style={footer}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Platefrom;
